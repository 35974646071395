import clsx from "clsx";

export function Card({ className, children, noPadding=false, ...props }) {
    const padding = noPadding ? "" : "p-6 py-7 sm:p-8 lg:p-12";
    return (
        <div className={
            clsx([
                className,
                padding,
                "rounded-xl bg-white shadow-[0px_0px_0px_1px_rgba(9,9,11,0.07),0px_2px_2px_0px_rgba(9,9,11,0.05)] dark:bg-zinc-900 dark:shadow-[0px_0px_0px_1px_rgba(255,255,255,0.1)]"
            ])
        }>
            {children}
        </div>
    );
}
